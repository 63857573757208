

export default {
  name: 'sentinelForm',
  components: {},
  props: {
    sitesData: {},
  },

  data() {
    return {

      range: {
        start: new Date(2020, 9, 17),
        end: new Date(2020, 9, 16),
      },

      selected_site: {},
      selected_site_id: "",
      dataForForm: this.sitesData,
      //bool d'attente de la requette sentinelle
      is_loading_sentinelles: true,
      active_sentinelles_null: true,
      picked: null,
      //class css qui sont appliquées quand les données ne sont chargées
      class_inactive_item: 'bg-gray-200 border-2 border-gray-400 opacity-25',
      active_sentinelle_list: [],
      //bool d'attente de la requette mesures
      is_loading_measurement: true,
      available_measurement: null,
      non_selected_measurment: [],
      time_range: {
        data: [
          
          { readable_time: '6 heures', request_time: 0.25 },
          { readable_time: '12 heures', request_time: 0.5 },
          { readable_time: '24 heures', request_time: 1 },
          { readable_time: '2 jours', request_time: 2 },
          { readable_time: '5 jours', request_time: 5 },
          { readable_time: '7 jours', request_time: 7 },
          { readable_time: '10 jours', request_time: 10 },
          { readable_time: '14 jours', request_time: 14 },
          { readable_time: '30 jours', request_time: 30 },
          { readable_time: '60 jours', request_time: 60 },
          { readable_time: '90 jours', request_time: 90 },

        ]
      },
      selected_time: '',
      sentinelle_type: '',
      all_prepared_queries:[],


    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    // GET request to retrieve active sentinelles
    getActiveSentinelle: function (event) {
      //on réinitialise la sélection du type de sentinelle
      this.picked = null;

      //on réinitialise les 2 booleen qui permettent de desactiver les radios button
      this.is_loading_sentinelles = true;
      this.active_sentinelles_null = true;
      //on réinitilise la liste des sentinelles actifs
      this.active_sentinelle_list = [];
      //on réinitialise la liste des mesures disponibles
      this.available_measurement = null;
      this.non_selected_measurment = [];

      //on récupere l'id du site choisi
      let site_id = event.target.value;
      this.selected_site_id = site_id;
      // on va voir sur l'API s'il y a des sentinelles actifs pour ce site     
      const BASE_URL = process.env.VUE_APP_BASEURL;
      this.axios.get(BASE_URL + "/sites/" + site_id + "/sentinelles/active").then((response) => {

        //on regarde si on a des data dans notre réponse. si oui on regarde quels sont les sentinelles actifs
        response.data.data.length != 0 ? this.retrieveActiveSentinelles(response.data.data[0]) : console.log("non");
        //console.log(response.data.data);
        (this.is_loading_sentinelles = false);

      });

    },

    retrieveActiveSentinelles: function (data) {

      this.active_sentinelles_null = false;
      //console.log(data.sentinelles)
      for (let sentinelle of data.sentinelles) {
        this.active_sentinelle_list.push(sentinelle.sentinelle_type);
        //console.log(sentinelle.sentinelle_type)
      }

    },

    checkIfpresent: function (name) {
      return this.active_sentinelle_list.includes(name);
    },

    getAssociatedMeasurements: function (event) {
      this.is_loading_measurement = true;
      let sentinelle_type = event.target.value;
      this.sentinelle_type = sentinelle_type
      //console.log(sentinelle_type)

      const BASE_URL = process.env.VUE_APP_BASEURL;

      this.axios.get(BASE_URL + "/" + sentinelle_type + "s" + "/measurements"+"/site/"+this.selected_site_id).then((response) => {
        this.is_loading_measurement = false;

        this.available_measurement = response.data.data;

        //console.log(this.available_measurement);
      });

    },

    remove(index) {
      var non_selected = this.available_measurement[index];
      this.non_selected_measurment.push(non_selected);
      var available_copy = this.available_measurement;
      available_copy.splice(index, 1)
      this.available_measurement = available_copy
      //this.available_measurement.splice(index, 1);
      //console.log(this.non_selected_measurment);

    },

    add(index) {
      var selected = this.non_selected_measurment[index];
      this.available_measurement.push(selected);
      var non_selected_copy = this.non_selected_measurment;
      non_selected_copy.splice(index, 1);
      this.non_selected_measurment = non_selected_copy;
      //console.log(this.non_selected_measurment);

    },
    construct_queries() {

      var colors = ["#10b981","#3b83f6","#f59e0b","#ff70a6", "#D8D8D8", "#10b981","#3b83f6","#f59e0b","#ff70a6","#10b981"]
      this.all_prepared_queries=[];

      //const toto = Object.assign({}, this.available_measurement);
      //
      const transformed_object = JSON.parse(JSON.stringify(this.available_measurement));
      //console.log(transformed_object[0])
      //console.log(transformed_object[0].readable_name)



      var counter = 0;
      for (let measurement of transformed_object) {
        counter++;
        let prepared_queries = {
          url: this.sentinelle_type,
          selected_site_id: this.selected_site_id,
          measurement_id: measurement.id,
          //measurment_name: measurement.readable_name,
          measurment_name: measurement.measurment_name,
          measurment_readable_name :measurement.readable_name,
          time: this.selected_time,
          unit:measurement.unit,
          color: colors[counter]

        }
        this.all_prepared_queries.push(prepared_queries);
      }
      this.$emit('clicked', this.all_prepared_queries)
      console.log(this.all_prepared_queries)

    }

  }
}


