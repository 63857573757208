<section class="aquacosme-graph ">
  <div>
    <div class='bg-white rounded-md pt-5 pb5'>

      <div v-if='loading_data'>

        <div class="flex justify-center">
          <img class=" rounded-lg animate-pulse w-96" src="@/assets/img/graph_1.svg">

        </div>


      </div>



      <div v-if='!loading_data' class="flex justify-around mt-5">
        <!---CARD--->
        <div
          class="bg-gray-300 lg:h-40 lg:w-10/12 lg:rounded-3xl md:h-32 md:w-9/12 md:rounded-xl h-20 w-72 rounded-xl  relative shadow-lg ">
          <!---MEASUREMENT_NAME--->

          <div class="flex justify-center">
            <div
              class="text-white text-center text-sm rounded md:text-base md:p-1 px-1 md:px-2 lg:text-2xl font-semibold lg:p-3 lg:rounded-xl lg:h-14 md:rounded-lg md:h-7 absolute -top-4 shadow-lg"
              :style="getColor(data_querie.color)">{{readable_parameter_name}} </div>

          </div>

          <!---VALEUR--->

          <div class="grid grid-cols-4     md:mt-4 md:ml5 md:grid-cols-4    lg:grid-cols-4 lg:mt-12 lg:ml-6 mt-2 ">
            <div class="flex flex-col items-center md:flex-col md:items-center lg:flex-col lg:items-center">
              <div class="text-gray-800 md:text-base lg:text-base text-sm"> Max </div>
              <div
                class="lg:text-base md:text-base rounded-full text-xs w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 shadow-sm  backdrop-blur-lg text-white font-medium text-center pt-3 md:pt-5 lg:pt-5"
                :style="getColor(data_querie.color)">{{maximum}}</div>

            </div>
            <div class="flex flex-col items-center md:flex-col md:items-center lg:flex-col lg:items-center">
              <div class="text-gray-800 md:text-base lg:text-base text-sm"> Min </div>
              <div
                class="lg:text-base md:text-base rounded-full text-xs w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 shadow-sm  backdrop-blur-lg text-white font-medium text-center pt-3 md:pt-5 lg:pt-5"
                :style="getColor(data_querie.color)">{{minimum}}</div>

            </div>
            <div class="flex flex-col items-center md:flex-col md:items-center lg:flex-col lg:items-center">
              <div class="text-gray-800 md:text-base lg:text-base text-sm"> Moyenne </div>
              <div
                class="lg:text-base md:text-base rounded-full text-xs w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 shadow-sm  backdrop-blur-lg text-white font-medium text-center pt-3 md:pt-5 lg:pt-5"
                :style="getColor(data_querie.color)">{{moyenne}}</div>

            </div>
            <div class="flex flex-col items-center md:flex-col md:items-center lg:flex-col lg:items-center">
              <div class="text-gray-800 md:text-base lg:text-base text-sm"> Médiane </div>
              <div
                class="lg:text-base md:text-base rounded-full text-xs w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 shadow-sm  backdrop-blur-lg text-white font-medium text-center pt-3 md:pt-5 lg:pt-5"
                :style="getColor(data_querie.color)">{{median}}</div>

            </div>

          </div>

        </div>

      </div>


      <div class='svg-container flex items-center justify-center'>
        <svg class="mb-4 pt-5 mt-10" v-bind:id="data_querie.measurment_name"> </svg>

      </div>



    </div>


  </div>



</section>