<div class="flex bg-gray-200  justify-center items-center pt-10">
  <div class=" bg-white rounded-lg shadow-xl ">
    <div class="grid justify-center py-4">
      <div class="flex bg-yellow-500 rounded-full md:p-4 p-2 shadow-md ">
        <img src="@/assets/img/placeholder.svg" class="h-10" />
      </div>
    </div>

    <div class="font-bold text-black text-xl text-center mt-4">

      <h2>ÉTAPE 1</h2>

      <div class="grid grid-cols-1 mt-5 mx-7">
        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Sélection du site</label>
        <select @change="getActiveSentinelle($event)" v-model="selected_site"
          class="py-2 px-3 rounded-lg border-2 border-yellow-300 mt-1 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent">
          <option v-for="option in dataForForm.data" :value="option.id">{{option.location_name}}</option>
        </select>

      </div>

      <div class="h-1 border-b-2 border-gray-400 mt-8 mx-20"></div>

      <div>


        <h1 class="mt-4 mb-8">ÉTAPE 2</h1>



        <h3 class="text-center uppercase md:text-sm text-xs text-gray-500 text-light font-semibold ">Sélection du type
          de sentinelle</h3>

        <div class="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-5 md:gap-8 mt-2 mx-7 rounded-lg p-2 "
          v-bind:class="[is_loading_sentinelles||active_sentinelles_null ? class_inactive_item :'']">


          <div class="grid grid-cols-1 items-center justify-items-center  rounded-lg bg-yellow-300 p-1 shadow-md "
            v-bind:class="checkIfpresent('aquacosme') ? '' : class_inactive_item">
            <img src="@/assets/img/sea.svg" class="h-10 mb-2" />
            <input type="radio" id="aquacosme" value="aquacosme" v-model="picked" class="mb-4"
              :disabled="is_loading_sentinelles||active_sentinelles_null || !checkIfpresent('aquacosme')"
              @change=" getAssociatedMeasurements($event)">
            <label class="uppercase md:text-sm text-xs text-gray-700 text-light font-semibold">Aquacosme</label>
          </div>

          <div class="grid grid-cols-1 items-center justify-items-center rounded-lg bg-yellow-300 p-1 shadow-md"
            v-bind:class="checkIfpresent('smart_nest') ? '' : class_inactive_item">
            <img src="@/assets/img/bird-house.svg" class="h-10 mb-2" />
            <input type="radio" id="smart_nest" value="smart_nest" v-model="picked" class="mb-4"
              :disabled="is_loading_sentinelles||active_sentinelles_null ||  !checkIfpresent('smart_nest')"
              @change=" getAssociatedMeasurements($event)">
            <label class="uppercase md:text-sm text-xs text-gray-700 text-light font-semibold">Smart Nest</label>

          </div>

          <div class="grid grid-cols-1 items-center justify-items-center rounded-lg bg-yellow-300 p-1 shadow-md"
            v-bind:class="checkIfpresent('open_feeder') ? '' : class_inactive_item">
            <img src="@/assets/img/bird.svg" class="h-10 mb-2" />
            <input type="radio" id="smart_nest" value="open_feeder" v-model="picked" class="mb-4"
              :disabled="is_loading_sentinelles||active_sentinelles_null ||  !checkIfpresent('open_feeder')"
              @change=" getAssociatedMeasurements($event)">
            <label class="uppercase md:text-sm text-xs text-gray-700 text-light font-semibold">Open feeder</label>

          </div>

          <div class="grid grid-cols-1 items-center justify-items-center rounded-lg bg-yellow-300 p-1 shadow-md"
            v-bind:class="checkIfpresent('beehive') ? '' : class_inactive_item">
            <img src="@/assets/img/bee.svg" class="h-10 mb-2" />
            <input type="radio" id="smart_nest" value="beehive" v-model="picked" class="mb-4"
              :disabled="is_loading_sentinelles||active_sentinelles_null || !checkIfpresent('beehive')"
              @change=" getAssociatedMeasurements($event)">
            <label class="uppercase md:text-sm text-xs text-gray-700 text-light font-semibold">Ruche</label>

          </div>


        </div>

      </div>



      <div class="h-1 border-b-2 border-gray-400 mt-8 mx-20"></div>


      <h2 class=" mt-4 mb-8">ÉTAPE 3</h2>


      <h3 class="text-center uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Sélection des
        paramètres à afficher</h3>



      <div v-if="is_loading_measurement "
        class="grid text-base md:text-lg md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2  md:justify-center gap-8">
        <div class="opacity-50 shadow-xl p-2 m-5 border-2 border-gray-500 bg-gray-200 rounded-lg mx-10 md:mx-8 lg:mx-8">
          <div class="flex-col justify-start items-center">
            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre A</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>

            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre B</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>

            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre C</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>
          </div>
        </div>

        <div class="opacity-50 shadow-xl p-2 m-5 border-2 border-gray-500 bg-gray-200 rounded-lg mx-10 md:mx-8 lg:mx-8">
          <div class="flex-col justify-start items-center">
            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre A</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>

            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre B</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>

            <div class="flex justify-center mb-2">
              <div class="bg-gray-400 text-gray-300 opacity-50 rounded-l pl-1 pr-1">paramètre C</div>
              <div class="bg-gray-500 flex w-5 p-0.5 items-center rounded-r opacity-50"><img
                  class="box-border h-4 w-4 opacity-30" src="@/assets/img/cross_2.svg" /></div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="!is_loading_measurement "
        class="grid text-base md:text-lg md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2  md:justify-center gap-8">
        <div class="shadow-xl p-2 m-5 border-2 border-green-700 bg-green-50 rounded-lg mx-10 md:mx-8 lg:mx-8 min-h-60 ">
          <div class="flex-col justify-start items-center">
            <div v-for="measure, index in available_measurement" class="flex mb-2">
              <div class="bg-green-400 rounded-l pl-1 pr-1">{{measure.readable_name}}</div>
              <div class="bg-green-600 flex w-5 p-0.5 items-center rounded-r" @click="remove(index)"><img
                  class="box-border h-4 w-4 " src="@/assets/img/moins_2.svg"></div>
            </div>


          </div>
        </div>

        <div class=" shadow-xl p-2 m-5 border-2 border-gray-500 bg-gray-100 rounded-lg mx-10 md:mx-8 lg:mx-8 min-h-60">
          <div class="flex-col justify-start items-center">

            <div v-for="measure, index in non_selected_measurment" class="flex mb-2">
              <div class="bg-gray-400 rounded-l pl-1 pr-1">{{measure.readable_name}}</div>
              <div class="bg-gray-600 flex w-5 p-0.5 items-center rounded-r" @click="add(index)"><img
                  class="box-border h-4 w-4 " src="@/assets/img/plus.svg"></div>

            </div>

          </div>
        </div>
      </div>



      <div class="h-1 border-b-2 border-gray-400 mt-8 mx-20"></div>

      <div class="font-bold text-black text-xl text-center mt-4 mb-7">
        <h2>ÉTAPE 4</h2>

      </div>

      <div class="grid grid-cols-1 mx-7">
        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Période temporelle (à partir
          d'aujourd'hui)</label>
        <select v-model="selected_time" :disabled="is_loading_measurement"
          class="py-2 px-3 rounded-lg border-2 border-yellow-300 mt-1 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent">
          <option v-for="option in time_range.data" :value="option.request_time">{{option.readable_time}}</option>
        </select>
      </div>

      <!--
      <p>ou</p>
      <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">choisir un range</label>

      <div class='flex justify-center'>

        <v-date-picker v-model="range" is-range>
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input :value="inputValue.start" v-on="inputEvents.start"
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-yellow-300" />
              <svg class="w-4 h-4 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
              <input :value="inputValue.end" v-on="inputEvents.end"
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-yellow-300" />
            </div>
          </template>
        </v-date-picker>

      </div>
-->


      <div class='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
        <!--<button class='w-auto bg-gray-500 hover:bg-gray-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Cancel</button>-->
        <button @click='construct_queries()'
          class='w-auto bg-yellow-500 hover:bg-yellow-600 rounded-lg shadow-lg font-medium text-gray-800 px-4 py-2'>Afficher
          les
          données</button>
      </div>

    </div>
  </div>
</div>