<template>
  <div class="bg-gray-200 pb-10">
    <div v-if="isLoading" class="flex justify-center items-center pt-10">
      <div
        class=" bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2"
      >
        <div class="flex items-center justify-center py-6">
          <div
            class="w-12 h-12 border-4 border-blue-600 rounded-full loader"
          ></div>
        </div>
      </div>
    </div>

    <sentinelForm
      v-if="!isLoading"
      :sitesData="sitesList"
      class="mb-4"
      @clicked="onPreparedQueriesClicked"
    />

    <div v-if="all_prepared_queries.length != 0">
      <div
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 px-8"
      >
        <aquacosmeGraph
          v-for="querie_data in all_prepared_queries"
          v-bind:key="querie_data.index"
          :data_querie="querie_data"
          class='shadow-xl '
        />
      </div>
    </div>
  </div>
</template>



<script>
import sentinelForm from "@/components/sentinel-form/index.vue";
import aquacosmeGraph from "@/components/aquacosme-graph/index.vue";

export default {
  name: "Sentinelles",

  data: function () {
    return {
      sitesList: null,
      isLoading: true,
      isDev: true,
      all_prepared_queries: [],
    };
  },
  created() {
    // Simple GET request using axios
    const BASE_URL = process.env.VUE_APP_BASEURL;
    this.axios.get(BASE_URL + "/sites").then((response) => {
      //console.log(response.data),
      this.isLoading = false;
      return (this.sitesList = response.data);
    });
  },

  components: {
    sentinelForm,
    aquacosmeGraph,
  },
  setup() {},
  methods: {
    onPreparedQueriesClicked(value) {
      this.all_prepared_queries = value;
      console.log(this.all_prepared_queries);
    },
  },
};
</script>


<style>
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>

