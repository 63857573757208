
import * as d3 from 'd3';
import { select, event } from 'd3-selection';

export const d3_bis = Object.assign({}, { select, event, })

export default {
  name: 'aquacosmeGraph',
  components: {},
  props: {
    data_querie: {},
    //id_graph:"conductivite",
  },
  data() {
    return {
      id_graph: '',
      color: '',
      borderColor: "",
      loading_data: true,
      data_graph: [],
      line: '',
      parsedData: null,
      measurment_name: "",
      readable_parameter_name:"",
      maximum: 0,
      minimum: 0,
      moyenne: 0,
      median: 0,
    }
  },
  watch: {
    data_querie: function () { console.log("data_querie changed"); this.loading_data = true; d3.select("#" + this.data_querie.measurment_name).selectAll('*').remove();this.title_display(); this.get_data(); }
  },
  computed: {},
  created() {



  },

  mounted() {
    //console.log(this.data_querie.measurment_name)
    
    this.measurment_name = this.data_querie.measurment_name.replace("_", " ").replace("_", " ");
    //this.readable_parameter_name = this.data_querie.measurment_readable_name;
    this.title_display();
    this.get_data();
    

    this.color = this.data_querie.color;
    this.borderColor = this.data_querie.color;

  },

  methods: {

    title_display(){
      this.readable_parameter_name = this.data_querie.measurment_readable_name;
    },

    get_data() {
      let base_sentinelle_url = this.data_querie.url + 's';
      let site_id = this.data_querie.selected_site_id;
      let parameter_id = this.data_querie.measurement_id;
      let time = this.data_querie.time
      var prepared_url = "/" + base_sentinelle_url + "/site/" + site_id + "/parameter/" + parameter_id;
      const BASE_URL = process.env.VUE_APP_BASEURL;

      this.axios.get(BASE_URL + prepared_url, { params: { time_range: time } }).then((response) => {

//console.log(response)

        this.data_graph = response.data;

        var formated_data = this.parseData(response.data)

        //console.log(formated_data)




        // c'est ici que tu viens calculer les min max mean median
        var max = Math.max.apply(Math, formated_data.map(function (o) { return o.value; }))
        this.maximum = max.toFixed(2);
        var min = Math.min.apply(Math, formated_data.map(function (o) { return o.value; }))
        this.minimum = min.toFixed(2);
        const median = arr => {
          const mid = Math.floor(arr.length / 2),
            nums = [...arr].sort((a, b) => a - b);
          return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
        };
        var ensemble_valeur = []

        var value = 0;
        for (let point of formated_data) {
          value = value + point.value;
          ensemble_valeur.push(point.value);
        }
        var total = formated_data.length;
        this.moyenne = ((value) / total).toFixed(2);
        this.median = median(ensemble_valeur).toFixed(2);

        this.loading_data = false;

        this.draw_zoom(formated_data)
        //


      });

    },

    parseData(data) {

      var expectedDateStringLength = 19
      var array_data_correct = [];
      var parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S")
      for (var point of data) {
        var date_ok=''
        var stringDateLength = point._time.split('+')[0].length
        if (stringDateLength - expectedDateStringLength >0){
          date_ok = parseDate(point._time.split('+')[0].slice(0, -(stringDateLength - expectedDateStringLength)))
        }
        else{
          date_ok = parseDate(point._time.split('+')[0])
        }


        //var date_ok = parseDate(point._time.split('+')[0].slice(0, -7))
        if (date_ok == null) {

          console.log("une date de nul: " + point._time)
        }

        array_data_correct.push(
          {
            value: point._value,
            date: date_ok
            //date:  point._time
            //date: (new Date (point._time.split('+')[0]))
          }
        )
      }
      //console.log(array_data_correct)
      console.log("finish")
      return array_data_correct;
    },


    getColor(color) {
      if (color == "#D8D8D8") {
        return { backgroundColor: "#979797", borderColor: color }

      }
      return { backgroundColor: color, borderColor: color }

    },

    getBorderColor(borderColor) {
      return { borderColor: borderColor }


    },





    draw_zoom(data) {

      function responsivefy(svg) {
        // container will be the DOM element
        // that the svg is appended to
        // we then measure the container
        // and find its aspect ratio
        const container = d3.select(svg.node().parentNode),
          width = parseInt(svg.style('width'), 10),
          height = parseInt(svg.style('height'), 10),
          aspect = width / height;

        //console.log(container)

        // set viewBox attribute to the initial size
        // control scaling with preserveAspectRatio
        // resize svg on inital page load
        svg.attr('viewBox', `0 0 ${width} ${height}`)
          .attr('preserveAspectRatio', 'xMinYMid')
          .call(resize);

        // add a listener so the chart will be resized
        // when the window resizes
        // multiple listeners for the same event type
        // requires a namespace, i.e., 'click.foo'
        // api docs: https://goo.gl/F3ZCFr
        d3.select(window).on(
          'resize.' + container.attr('id'),
          resize
        );

        // this is the code that resizes the chart
        // it will be called on load
        // and in response to window resizes
        // gets the width of the container
        // and resizes the svg to fill it
        // while maintaining a consistent aspect ratio
        function resize() {
          const w = parseInt(container.style('width'));
          svg.attr('width', w);
          svg.attr('height', Math.round(w / aspect));
        }
      }

      var id = this.data_querie.measurment_name;
      //console.log(id)
      var color = this.data_querie.color;
      var legend_text = this.data_querie.unit;


      data.sort((a, b) => a.date - b.date);

      var svg = d3.select("#" + id)
      svg.selectAll('*').remove();


      //var svg = d3.select("#" + id).call(responsivefy)

      //var svg = d3.select("#" + id)
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", 1000)
        .attr("height", 500)
        .call(responsivefy);


      var margin = { top: 15, right: -15, bottom: 15, left: 50 },
        width = +svg.attr("width") - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom;

      //var x = d3.scaleTime().range([0, width]);
      //var y = d3.scaleLinear().rangeRound([height, 0]);

      var x = d3.scaleTime()
        .domain(d3.extent(data, d => d.date))
        .range([0, width])
      //.range([margin.left, width - margin.right])

      var y = d3.scaleLinear()
        .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)]).nice()
        .range([height - margin.bottom, margin.top])

      var xAxis = svg.append("g")
        .attr("class", "x-axis")
        .attr("clip-path", "url(#clip)")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x)
          .tickSizeOuter(0));
      xAxis


      var yAxis = svg.append("g")
        .attr("class", "y-axis")
        .attr("transform", `translate(${margin.left},0)`)

        .call(d3.axisLeft(y));
      yAxis

      yAxis
        .append("text")
        .attr("fill", "#000")
        .attr("x", 10)
        .attr("y", 10)
        .attr("dy", "0.71em")
        .attr("text-anchor", "start")
        .attr("font-size", "200%")
        .text(legend_text);

      var line = d3.line()
        .defined(d => !isNaN(d.value))
        .x(d => x(d.date))
        .y(d => y(d.value))

      var defs = svg.append("defs").append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("x", margin.left)
        .attr("width", width - margin.right)
        .attr("height", height);
      defs

      var path = svg.append("path")
        .datum(data)
        .attr("class", "path")
        .attr("fill", "none")
        .attr("clip-path", "url(#clip)")
        .attr("stroke", color)
        .attr("stroke-width", 1.5)
        .attr("d", line);
      path

      svg.call(zoom);

      function zoom(svg) {
        var extent = [
          [margin.left, margin.top],
          [width - margin.right, height - margin.top]
        ];
        var zooming = d3.zoom()
          .scaleExtent([1, 5])
          .translateExtent(extent)
          .extent(extent)
          .on("zoom", function (event) { zoomed(event) });

        svg.call(zooming);

        function zoomed(event) {

          x.range([margin.left, width - margin.right]
            .map(d => event.transform.applyX(d)));

          svg.select(".path")
            .attr("d", line);

          svg.select(".x-axis")
            .call(d3.axisBottom(x)
              .tickSizeOuter(0));
        }
      }
      svg.call(hover)

      function hover() {

        var bisect = d3.bisector(d => d.date).left,
          //format = d3.format("+.0%"),
          dateFormat = d3.timeFormat("%Y-%b-%d-%Hh%M")

        var focus = svg.append("g")
          .attr("class", "focus")
          .style("display", "none");

        focus.append("line")
          .attr("stroke", "#666")
          .attr("stroke-width", 1)
          .attr("y1", -height)
        //.attr("y1", -height + margin.top)
        //.attr("y2", -margin.bottom);

        focus.append("circle")
          .attr("class", "circle")
          .attr("r", 5)
          .attr("dy", 5)
          .attr("stroke", color)
          .attr("fill", color);
        //.attr("fill", "#fff");

        focus.append("text")
          .attr("text-anchor", "middle")
          .attr("dy", ".35em");

        focus.append("rect")
          .attr("id", "tooltip")
          .attr("stroke", "black")
          //.attr("rx", 5)
          //.attr("ry", 5)
          .attr("stroke-width", 2)
          .attr("width", 150)
          .attr("height", 50)
          .attr("fill", color)
          .attr("fill-opacity", .7)
          .attr("text-anchor", "middle")
          .attr("dy", ".35em")

        focus.append("text")
          .attr("id", "text_tooltip")
          .attr("fill", 'black')
          .attr("fill-opacity", 1)
          .attr("width", 100)
          .attr("height", 50)

        var overlay = svg.append("rect")
          .attr("class", "overlay")
          .attr("x", margin.left)
          .attr("y", margin.top)
          .attr("fill", "transparent")
          .attr("width", width - margin.right - margin.left - 1)
          .attr("height", height - margin.bottom - margin.top)
          .on("mouseover", () => focus.style("display", null))
          .on("mouseout", () => focus.style("display", "none"))
          .on("mousemove", function (event) { mousemove(event) });
        overlay

        function mousemove(event) {
          // la magie arrive ici : nouvelle façon de choper la position du curseur dans le référentiel du graph
          var x0 = x.invert(d3.pointer(event)[0]);

          var i = bisect(data, x0, 1),
            d0 = data[i - 1],
            d1 = data[i],
            d = x0 - d0.date > d1.date - x0 ? d1 : d0;

          focus.select("line")
            .attr("transform",
              "translate(" + x(d.date) + "," + height + ")");

          focus.selectAll(".circle")
            .attr("transform",
              "translate(" + x(d.date) + "," + y(d.value) + ")");

          focus.select("text")
            .attr("transform",
              "translate(" + x(d.date) + "," + (height + margin.bottom) + ")")
            //.html(+ dateFormat(d.date))
            .text(dateFormat(d.date))
          //.text(d.value);



          focus.select("#tooltip")
            .attr("transform",
              "translate(" + x(d.date) + "," + 5 + ")")

          focus.select('#text_tooltip')
            .attr("transform",
              "translate(" + x(d.date) + "," + 35 + ")")
            .text("\u00A0" + "\u00A0" + "valeur: " + d.value)

        }
      }
    }

  }


}



